
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'YotpoSiteScore',
  props: {
    text: {
      type: String,
      required: false,
      default: '100.000+'
    }
  },
  computed: {
    ...mapState({
      loadingYotpoReviews: state => state.yotpo.loadingReviews,
    }),
    ...mapGetters({
      getStoreReviewsBottomline: 'yotpo/getStoreReviewsBottomline'
    }),
    defaultScore () {
      return 0
    },
    score () {
      return this.getStoreReviewsBottomline?.average_score ?? this.defaultScore
    },
  },
}
